import * as Sentry from "@sentry/react"
import React from "react"
import ReactDOM from "react-dom/client"
import "@SIAnalytics/ovision-design-system"
import "antd/dist/antd.variable.min.css"
import "ol/ol.css"
import "ol-ext/dist/ol-ext.css"
import "./index.scss"
import "./i18n"
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom"

import App from "./App"
import reportWebVitals from "./reportWebVitals"

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_DOMAIN?.includes("dev")
    ? "development"
    : process.env.REACT_APP_DOMAIN?.includes("beta")
    ? "testing"
    : "production",
})

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
