import {
  AdminCreditHistoryResult,
  AdminInferenceHistory,
  AdminInferenceHistoryResult,
  CONTROL_SWIPE,
  COORDINATE_DAEJEON,
  FitScreenOutlined,
  generateOlMap,
  generateOsmLayer,
  generateVectorLayerForLand,
  generateVectorLayerForObjectDetection,
  generateVectorLayerForSegmentation,
  generateVectorSourceFromGeoJson,
  generateVectorSourceFromWkb,
  getAdminInferenceHistoriesId,
  getAdminInferenceHistoriesIdResults,
  getTileLayer,
  initAdminInferenceHistory,
  initAdminInferenceHistoryResult,
  isInitialAdminInferencceHistoryResult,
  isInitialAdminInferenceHistory,
  LAYER_TILE_ADMIN_VIEWER,
  LAYER_TILE_ADMIN_VIEWER_Z_INDEX,
  removeLayers,
} from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import { ArrowBackOutlined, IconButton, InfoOutlined } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import { Map as OlMap, View } from "ol"
import Swipe from "ol-ext/control/Swipe"
import { Control } from "ol/control"
import { defaults as defaultControls } from "ol/control/defaults"
import { Extent } from "ol/extent"
import TileLayer from "ol/layer/Tile"
import TileSource from "ol/source/Tile"
import React, { useEffect, useRef, useState } from "react"

import styles from "./Viewer.module.scss"
import ViewerDetail from "./ViewerDetail"

type Props = {
  selectedRecordNote: AdminCreditHistoryResult
  onBackButtonClick?: React.MouseEventHandler<HTMLElement>
}

function Viewer(props: Props) {
  const [loadingMap, setLoadingMap] = useState<boolean>(false)

  const [inferenceHistory, setInferenceHistory] = useState<AdminInferenceHistory>(initAdminInferenceHistory)
  const [inferenceHistoryResult, setInferenceHistoryResult] =
    useState<AdminInferenceHistoryResult>(initAdminInferenceHistoryResult)
  const [backLoading, setBackLoading] = useState<boolean>(false)

  const [resultExtent, setResultExtent] = useState<Extent>([])
  const [isInfoVisible, setIsInfoVisible] = useState<boolean>(false)

  const mapContainerRef = useRef<HTMLDivElement>(null)
  const mapRef = useRef<OlMap | null>(null)
  const raster = generateOsmLayer()

  useEffect(() => {
    if (mapRef.current) return
    if (!mapContainerRef.current || !mapRef) return

    mapRef.current = generateOlMap(
      [raster],
      mapContainerRef.current,
      new View({ center: COORDINATE_DAEJEON, zoom: 0 }),
      defaultControls({ rotate: false }),
    )
    mapRef.current.on("loadstart", () => setLoadingMap(true))
    mapRef.current.on("loadend", () => setLoadingMap(false))

    return () => {
      mapRef.current?.getAllLayers().map((_layer) => mapRef.current?.removeLayer(_layer))
      mapRef.current?.un("loadstart", () => setLoadingMap(true))
      mapRef.current?.un("loadend", () => setLoadingMap(false))
      mapRef.current?.setTarget(undefined)
      mapRef.current = null
    }
  }, [mapContainerRef.current])
  useEffect(() => {
    const inferenceId = props.selectedRecordNote.creditHistory.causedByOnlyId
    if (!inferenceId) return
    const setAdminInferenceHistoriesIdAsync = async () => {
      if (!inferenceId) return
      try {
        const _history = await getAdminInferenceHistoriesId(inferenceId)
        setInferenceHistory(_history)
      } catch (e) {
        captureException(e)
      }
    }
    const setAdminInferenceHistoriesIdResultsAsync = async () => {
      if (!inferenceId) return
      try {
        const _result = await getAdminInferenceHistoriesIdResults(inferenceId)
        setInferenceHistoryResult(_result)
      } catch (e) {
        captureException(e)
      }
    }

    void setAdminInferenceHistoriesIdAsync()
    void setAdminInferenceHistoriesIdResultsAsync()
  }, [props.selectedRecordNote])
  useEffect(() => {
    if (isInitialAdminInferenceHistory(inferenceHistory)) return
    setIsInfoVisible(inferenceHistory.inferenceHistory.inferenceStatus !== "COMPLETED")
    const addSceneLayer = async () => {
      const _aoiExtent = generateVectorSourceFromWkb(inferenceHistory.inferenceHistory.aoi).getExtent()
      const _tiles: TileLayer<TileSource>[] = []
      let index = 0
      try {
        for await (const scene of inferenceHistory.inferenceHistory.scenes) {
          const sceneUrl = scene.wmsUrl || scene.sceneURI
          if (!sceneUrl) continue
          _tiles.push(
            await getTileLayer(sceneUrl, _aoiExtent, LAYER_TILE_ADMIN_VIEWER, LAYER_TILE_ADMIN_VIEWER_Z_INDEX + index),
          )
          index++
        }
        const resultSceneUrl = inferenceHistoryResult.scene.wmsUrl
        if (resultSceneUrl) {
          _tiles.push(
            await getTileLayer(resultSceneUrl, _aoiExtent, LAYER_TILE_ADMIN_VIEWER, LAYER_TILE_ADMIN_VIEWER_Z_INDEX + index),
          )
        }
      } catch (e) {
        captureException(e)
      }

      _tiles.map((_tile) => mapRef.current?.addLayer(_tile))
      if (_tiles.length === 2) {
        const olSwipe = new Swipe()
        olSwipe.set("name", CONTROL_SWIPE)
        mapRef.current?.addControl(olSwipe)
        olSwipe.addLayer(_tiles[0], false)
        olSwipe.addLayer(_tiles[1], true)
      }
      mapRef.current?.getView().fit(_aoiExtent)
      setResultExtent(_aoiExtent)
    }
    const addResultLayer = async () => {
      if (!inferenceHistoryResult.geojsonURI) return
      const _source = generateVectorSourceFromGeoJson(inferenceHistoryResult.geojsonURI)
      const _aiPackDisplayName = inferenceHistory.inferenceHistory.aiPack.displayName.replace(" ", "").toLowerCase()
      let aiPackType: "LAND_COVER_SEG" | "SEG" | "OBD"
      if (_aiPackDisplayName === "landcoversegmentation") aiPackType = "LAND_COVER_SEG"
      else if (_aiPackDisplayName.includes("segmentation")) aiPackType = "SEG"
      else aiPackType = "OBD"
      let _vector
      if (aiPackType === "LAND_COVER_SEG") _vector = generateVectorLayerForLand(_source)
      else if (aiPackType === "SEG") _vector = generateVectorLayerForSegmentation(_source)
      else _vector = generateVectorLayerForObjectDetection(_source)

      mapRef.current?.addLayer(_vector)
    }

    void addSceneLayer()
    void addResultLayer()

    return () => {
      if (!mapRef.current) return
      mapRef.current.getControls().forEach((ctrl) => {
        if (ctrl instanceof Control && ctrl.get("name") === CONTROL_SWIPE) mapRef.current?.removeControl(ctrl)
      })
      removeLayers(mapRef.current, LAYER_TILE_ADMIN_VIEWER)
    }
  }, [inferenceHistory, inferenceHistoryResult])

  return (
    <div className={styles.viewerMap}>
      <header>
        <div className={styles.left}>
          <IconButton
            size={"small"}
            type={"square"}
            icon={<ArrowBackOutlined />}
            loading={backLoading}
            onClick={props.onBackButtonClick}
          />
          <p>{inferenceHistory.inferenceHistory.jobName}</p>
        </div>
        <div className={styles.right}>
          <IconButton icon={<FitScreenOutlined />} onClick={() => mapRef.current?.getView().fit(resultExtent)}>
            {"맞춤"}
          </IconButton>
          <IconButton
            active={isInfoVisible || undefined}
            icon={<InfoOutlined />}
            onClick={() => setIsInfoVisible((prev) => !prev)}
          >
            {"정보"}
          </IconButton>
        </div>
      </header>
      <div className={styles.mapContainer} ref={mapContainerRef}>
        <progress className={cn("tile-load-progress", loadingMap && "tile-loading")} max={"100"} value={"100"} />
        {isInfoVisible && <ViewerDetail adminInferenceHistory={inferenceHistory} />}
      </div>
    </div>
  )
}

export default Viewer
