import { Nullable } from "../../common/common"
import { AccountId } from "../../common/utils/accountUtil"
import { CreditAllResultType } from "./creditAllResultsDto"
import { CreditRechargeResultType } from "./creditRechargeResultsDto"
import { CurrencyType, isCurrencyValid } from "./orderDto"

export const isInvoiceTypeValid = (type: string | null): type is InvoiceType => {
  return type === "PAID" || type === "FREE" || type === "COMPENSATION" || type === "EXPIRATION"
}

export type InvoiceType =
  | Extract<CreditRechargeResultType, "PAID" | "FREE">
  | Extract<CreditAllResultType, "COMPENSATION" | "EXPIRATION">
  | ""

export type AdminInvoiceRequestType = {
  accountIds: string[]
  creditAmount: number
  document?: string
}
export type AdminInvoicePaidRequestType = AdminInvoiceRequestType & {
  price: number
  currency: CurrencyType
}
export type AdminInvoiceFreeRequestType = AdminInvoiceRequestType
export type AdminInvoiceCompensationRequestType = AdminInvoiceRequestType

export type AdminInvoice = {
  id: string
  accountId: AccountId
  creditAmount: number
  paid: boolean
  price: number
  currency: CurrencyType
  documentExists: boolean
  documentName: string
  invoiceTime: Date | ""
  invoiceKey: string
  type: InvoiceType
}
export type AdminInvoices = Record<"invoices", AdminInvoice[]>
export type AdminInvoiceResults = Record<"results", AdminInvoice[]>

type AdminInvoiceToNullable = Omit<AdminInvoice, "currency" | "invoiceTime" | "type">
export type AdminInvoiceDto = {
  currency: string | null
  invoiceTime: string | null
  type: string | null
} & {
  [key in keyof AdminInvoiceToNullable]: Nullable<AdminInvoiceToNullable[key]>
}
export type AdminInvoicesDto = Record<"invoices", AdminInvoiceDto[] | null>
export type AdminInvoiceResultsDto = {
  result: { [id: string]: AdminInvoiceDto } | null
}

export const adminUserInvoiceFromDto = (dto: AdminInvoiceDto | null): AdminInvoice => {
  if (!dto) return initAdminInvoice()
  const _currency = isCurrencyValid(dto.currency) ? dto.currency : ""
  const _type = isInvoiceTypeValid(dto.type) ? dto.type : ""
  return {
    id: dto.id ?? "",
    accountId: dto.accountId ?? "user:-1",
    creditAmount: dto.creditAmount ?? 0,
    paid: dto.paid ?? false,
    price: dto.price ?? 0,
    currency: _currency,
    documentExists: dto.documentExists ?? false,
    documentName: dto.documentName ?? "",
    invoiceTime: dto.invoiceTime ? new Date(dto.invoiceTime) : "",
    invoiceKey: dto.invoiceKey ?? "",
    type: _type,
  }
}
export const adminUserInvoicesFromDto = (dto: AdminInvoicesDto): AdminInvoices => {
  return { invoices: dto.invoices?.map((invoice) => adminUserInvoiceFromDto(invoice)) ?? [] }
}
export const adminUserInvoiceResultsFromDto = (dto: AdminInvoiceResultsDto): AdminInvoiceResults => {
  if (!dto.result) return initAdminInvoiceResults()
  const _data: AdminInvoice[] = []
  for (const _invoice in dto.result) _data.push(adminUserInvoiceFromDto(dto.result[_invoice]))
  return { results: _data }
}

export const initAdminInvoice = (): AdminInvoice => {
  return {
    id: "",
    accountId: "user:-1",
    creditAmount: 0,
    paid: false,
    price: 0,
    currency: "",
    documentExists: false,
    documentName: "",
    invoiceTime: "",
    invoiceKey: "",
    type: "",
  }
}
export const initAdminInvoiceResults = (): AdminInvoiceResults => {
  return {
    results: [],
  }
}
